<template>
    <div>
        <Preloader v-if='loading'/>

        <div class='widget'>
            <h2 class='mb-4'>Моя зарплата</h2>

            <div class='d-flex mb-4'>
                <b-form-select
                    class='mr-4'
                    :options="[{value: null, text: 'Выберите Год', disabled: true}, ...years]"
                    @change='handleSelectYear'
                    :value='selectedYear'
                />

                <div
                    role='radiogroup'
                    tabindex='-1'
                    class='btn-group-toggle btn-group bv-no-focus-ring'
                >
                    <label
                        v-for='(month, idx) in getMonths'
                        :key='idx'
                        class='btn btn-outline-primary'
                        :class="{ 'active': isActiveMonth(month) }"
                        :title='month.title'
                    >
                        <input
                            :id='`salary-option-${idx}`'
                            type='radio'
                            name='teacher-salary-months'
                            :value='month'
                            :disabled='isActiveMonth(month)'
                            @input='monthSelect(month)'
                        >
                        <span>{{ month.name }}</span>
                    </label>
                </div>

                <div class='ml-4 align-self-center'>
                    <b-button variant='outline-primary' type='button' title='Очистить фильтр' @click='clearFilters'>
                        <i class='fa fa-close'></i>
                    </b-button>
                </div>
            </div>

            <div class="d-flex justify-content-end ml-auto align-items-center m-4">
                <TeacherSalariesTotal v-if="this.total" :total="this.total"/>
                <div>
                    <b-button :disabled="approved" variant='success' type='button' @click='handleApprove'>Подтвердить</b-button>
                </div>
            </div>

            <TeacherSalaryTable :students="students" :penalties="penalties" :payments="payments"/>

        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import Preloader from '@/components/Preloader';
import TeacherSalariesTotal from '@/components/admin/teacher-salary/TeacherSalariesTotal';
import TeacherSalaryTable from '@/components/admin/teacher-salary/TeacherSalaryTable';

function buildYears(startYear = 2018) {
    const years = [];

    const currentYear = new Date().getFullYear();
    let counter = startYear;

    while (counter <= currentYear) {
        years.push(counter.toString());
        counter++;
    }

    return years;
}

const years = buildYears();

export default {
    name: 'TeacherProfileSalary',
    components: {Preloader, TeacherSalariesTotal, TeacherSalaryTable},
    data() {
        return {
            years,
            selectedYear: new Date().getFullYear(),
            selectedMonth: {},
            students: [],
            total: null,
            loading: false,
            penalties: [],
            payments: [],
            visibleStudentRows: [],
            approved: false,
        };
    },
    async beforeRouteUpdate(to, from, next) {
        this.loading = true;
        await Promise.all([
            this.fetchTotal(to.query),
            this.fetchStudents(to.query),
            this.fetchPenalties(to.query),
            this.fetchPayments(to.query),
            this.fetchApproved(to.query),
        ]);
        this.loading = false;
        await next();
    },
    async created() {
        dayjs.extend(isBetween);
        await this.fillFiltersOnInit();
        this.loading = true;
        await Promise.all([
            this.fetchTotal(this.$route.query),
            this.fetchStudents(this.$route.query),
            this.fetchPenalties(this.$route.query),
            this.fetchPayments(this.$route.query),
            this.fetchApproved(this.$route.query),
        ]);
        this.loading = false;
    },
    computed: {
        dayjs() {
            return dayjs
        },
        ...mapGetters({
            getUserId: 'user/getUserId',
        }),
        isStudentRowVisible() {
            return (idx) => this.visibleStudentRows.includes(idx);
        },
        getMonths() {
            return [
                {
                    name: 'Январь',
                    title: `c 1 января по 31 января ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-01-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-01-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Февраль',
                    title: `c 1 февраля по 28 февраля ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-02-01`).format('YYYY-MM-DD'),
                    date_to: this.selectedYear % 4 === 0 ? dayjs(`${this.selectedYear}-02-29`).format('YYYY-MM-DD') : dayjs(`${this.selectedYear}-02-28`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Март',
                    title: `c 1 марта по 31 марта ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-03-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-03-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Апрель',
                    title: `c 1 апреля по 30 апреля ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-04-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-04-30`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Май',
                    title: `c 1 мая по 31 мая ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-05-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-05-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Июнь',
                    title: `c 1 июня по 30 июня ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-06-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-06-30`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Июль',
                    title: `c 1 июль по 31 июля ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-07-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-07-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Август',
                    title: `c 1 августа по 31 августа ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-08-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-08-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Сентябрь',
                    title: `c 1 сентября по 30 сентября ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-09-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-09-30`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Октябрь',
                    title: `c 1 октября по 31 октября ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-10-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-10-31`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Ноябрь',
                    title: `c 1 ноября по 30 ноября ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-11-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-11-30`).format('YYYY-MM-DD'),
                },
                {
                    name: 'Декабрь',
                    title: `c 1 декабря по 31 декабря ${this.selectedYear}`,
                    date_from: dayjs(`${this.selectedYear}-12-01`).format('YYYY-MM-DD'),
                    date_to: dayjs(`${this.selectedYear}-12-31`).format('YYYY-MM-DD'),
                },
            ];
        },
    },
    methods: {
        toggleStudentRowVisibility(rowIdx, e) {
            if (this.isStudentRowVisible(rowIdx)) {
                this.visibleStudentRows = this.visibleStudentRows.filter(row => row !== rowIdx);
            } else {
                this.visibleStudentRows.push(rowIdx);
            }
        },
        async handleApprove() {
            try {
                await this.$axios.post(`crm/teachers/${this.getUserId}/teacher-salary/confirmed`, {
                    date_from: this.selectedMonth.date_from,
                    date_to: this.selectedMonth.date_to,
                });
                this.loading = true;
                await this.fetchTotal(this.$route.query);
                await this.fetchStudents(this.$route.query);
                await this.fetchPenalties(this.$route.query);
                await this.fetchPayments(this.$route.query);
                await this.fetchApproved(this.$route.query);
                this.loading = false;
                this.$toasted.success('Зарплата подтверждена', {position: 'bottom-right'});
            } catch (ex) {
                console.log('cant send approve: ', ex);
                this.$toasted.error(ex, {position: 'bottom-right'});
            }
        },
        async fetchStudents(query = null) {
            try {
                const {data} = await this.$axios.get(`crm/teachers/${this.getUserId}/teacher-salary`, {
                    params: {
                        ...query,
                    }
                });

                this.students = data.data;

                this.formDataStudents = JSON.parse(JSON.stringify(data.data));
            } catch (ex) {
                console.log('cant fetch students: ', ex);
            }
        },
        async fetchPenalties(query = null) {
            try {
                const {data} = await this.$axios.get(`crm/teachers/${this.getUserId}/teacher-salary-list`, {
                    params: {
                        ...query,
                        types: [3, 4],
                        limit: 1000,
                    }
                });
                this.penalties = data.data;
            } catch (ex) {
                console.log('cant fetch penalties: ', ex);
            }
        },
        async fetchPayments(query = null) {
            try {
                const {data} = await this.$axios.get(`crm/teachers/${this.getUserId}/teacher-payouts`, {
                    params: {
                        ...query,
                    }
                });
                this.payments = data.data;
            } catch (ex) {
                console.log('cant fetch payments: ', ex);
            }
        },
        async fetchTotal(query = null) {
            try {
                const {data} = await this.$axios.get(`crm/teachers/teacher-salary/total`, {
                    params: {
                        ...query,
                        teacher_id: this.getUserId,
                    }
                });
                this.total = data;
            } catch (ex) {
                console.log('cant fetch teacher salary total: ' + ex);
            }
        },
        async fetchApproved(query = null) {
            try {
                const {data} = await this.$axios.get(`crm/teachers/${this.getUserId}/teacher-salary/was-confirmed`, {params: {...query}});
                this.approved = !data;
            } catch (ex) {
                console.log('cant fetch teacher salary total: ' + ex);
            }
        },
        async fillFiltersOnInit() {
            await this.fillMonthOnInit();
        },
        async fillMonthOnInit() {
            const query = this.$route.query;

            if (query.date_from) {
                const year = dayjs(query.date_from).format('YYYY');

                if (this.years.includes(year)) {
                    this.selectedYear = year;
                } else {
                    this.selectedYear = new Date().getFullYear();
                }
            }

            if (query.date_from && query.date_to) {
                for (let i = 0; i < this.getMonths.length; i++) {
                    const month = this.getMonths[i];
                    const correct = query.date_from === month.date_from && query.date_to === month.date_to;

                    if (correct) {
                        this.selectedMonth = month;
                        break;
                    }
                }
            } else {
                await this.setTodayMonth();
            }
        },
        async setTodayMonth() {
            for (let i = 0; i < this.getMonths.length; i++) {
                const month = this.getMonths[i];
                const correct = dayjs().isBetween(dayjs(month.date_from), dayjs(month.date_to), 'day', '[');

                if (correct) {
                    this.selectedMonth = month;
                    const query = Object.assign(
                        {},
                        this.$route.query,
                        {
                            date_from: month.date_from,
                            date_to: month.date_to,
                        },
                    );
                    await this.$router.replace({query});
                    break;
                }
            }
        },
        handleSelectYear(year) {
            const query = Object.assign(
                {},
                this.$route.query,
                {
                    date_from: `${year}-${dayjs(this.selectedMonth.date_from).format('MM-DD')}`,
                    date_to: `${year}-${dayjs(this.selectedMonth.date_to).format('MM-DD')}`,
                },
            );

            this.selectedYear = year;
            this.$router.replace({query});
        },
        async monthSelect(month) {
            const query = Object.assign(
                {},
                this.$route.query,
                {
                    date_from: month.date_from,
                    date_to: month.date_to,
                },
            );

            this.selectedMonth = month;
            await this.$router.replace({query});
        },
        async clearFilters() {
            if (this.$route.query && Object.keys(this.$route.query).length > 0) {
                this.selectedYear = new Date().getFullYear();
                await this.setTodayMonth();
                await this.$router.replace({query: {}});
            }

            return false;
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        async handlePageSelect(page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, {page});
                await this.$router.push({query: query});
            }

            return false;
        },
        isActiveMonth(month) {
            return month.name === this.selectedMonth.name;
        },
    },
};
</script>

<style lang='scss' scoped>
.teacher-salary-badge {
    min-width: 85px;
    padding: 10px 14px;
    text-align: center;
}

.tsmp-comment {
    width: 220px;
}

.custom-list-group-horizontal {
    border-radius: 0.5rem;

    .list-group-item {
        padding: 0.375rem 1rem;
    }
}
</style>